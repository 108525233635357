import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_Row,
  createMRTColumnHelper,
} from 'material-react-table';
import { Box, Button, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IContact } from './ICustomer';
import { useEffect, useState } from 'react';

const columnHelper = createMRTColumnHelper<IContact>();

const columns = [
  columnHelper.accessor('id', {
    header: 'ID',
    size: 40,
  }),


  columnHelper.accessor('phone', {
    header: 'Telefone',
    size: 120,
  }),

  columnHelper.accessor('stage', {
    header: 'Etapa',
    size: 220,
  }),
  columnHelper.accessor('finished', {
    header: 'Finalizado',
    Cell: (props) => {
      return <>
        <Typography color={props.row.original.finished ? 'green': 'red'}>
          {props.row.original.finished ? 'Finished' : 'Not Finished'}
        </Typography>
      </>
    },
    size: 220,
  }),
];



export const ContactInteractionStateTable = (props: {
  data: IContact[],
}) => {

  const handleExportRows = (rows: MRT_Row<IContact>[]) => {
    // const rowData = rows.map((row) => row.original);
 
  };

  const handleExportData = () => {
 
  };

  const table = useMaterialReactTable({
    columns,
    data: props.data,
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        {/* <Button
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button> */}
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};


import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
// import { BarChart, PieChart } from '@mui/x-charts';
import { IContact } from './ICustomer';
import { ContactInteractionStateTable } from './ContactInteractionStateTable';
import axios from 'axios'
import { CircularProgress } from '@mui/material';


function extractNumbers(input: string): string | null {
  const [phone, _] = input.split('@'); // Split the input at the '@' symbol
  const pattern = /\+?55?\s?\(?(\d{2})\)?\s?(\d{4,5})[- ]?(\d{4})/;
  const match = phone.match(pattern);

  if (match) {
    return match.slice(1).join('');
  }

  return null;
}



const ids = [
  'C659d4a70e2005624a47a8e0e',
  'C6656310ec180667b4cb9b369',
  'C665631b2bd944dba4e925009',
  'C665631cd689d1f50ac8acef0',
  'C665631eec4f140820ed2e879',
  'C66563203cf267487051ec1c2',
  'C665632189a6e4f3908a7e28d',
  'C665632360584356159f5bbb0',
  'C665630600701f36347caa21d',
  'C66563081df2ad4b63edf7e7f',
  'C665632644c22564cc31ca1bf',
  'C6656327a149e82174e63644a',
  'C66563298695e3f459ad4dc02'
]



function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export function ContactInteractionState() {
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false)
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [data, setData] = React.useState<IContact[]>([])
  const calculateStageCounts = (): number[] => {
    const stageCounts: { [key: string]: number } = {};

    data.forEach(customer => {
      if (customer.stage in stageCounts) {
        stageCounts[customer.stage]++;
      } else {
        stageCounts[customer.stage] = 1;
      }
    });

    // Map the counts to match the format expected by the BarChart component
    const counts = Object.values(stageCounts);
    return counts;
  };
  const xAxisLabels = Object.keys(data.reduce((acc, curr) => ({ ...acc, [curr.stage]: true }), {}));

  const setLists = React.useCallback(async () => {
    const contactListAux: IContact[] = []
    setLoading(true)
    for (let k = 0; k < ids.length; k++) {
      const response = await axios.get(`https://api.multiweb.plus/act/list/cards/?key=65a93673e5b52462823fee93&id=${ids[k]}`)
      const list = response.data.components[0].list
      const listName = response.data.components[0].reference
      for (let i = 0; i < list.length; i++) {
        const contact = list[i]
        contactListAux.push({
          id: Math.random(),
          phone: extractNumbers(contact.id) ?? 'Error',
          stage: listName,
          finished: k === ids.length - 1
        })
      }
    }

    setData(contactListAux)
    setLoading(false)

  }, [])

  React.useEffect(() => {
    setLists()


  }, [setLists])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Rastrear contatos
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box display={'flex'} justifyContent={'center'}>
                  <img style={{
                    width: '100px'
                  }} src="/humano-lab-logo.png" alt="logo" />
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                {
                  !loading ? (
                    <>
                      <ContactInteractionStateTable data={data} />
                    </>
                  ) : <Box display={"flex"} justifyContent={"center"}>
                    <CircularProgress />
                  </Box>
                }
              </Grid>
              {/* Recent Deposits */}

            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
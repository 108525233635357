import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Dashboard from "./Dashboard"
import { ContactInteractionState } from "./ContactInteractionState"



export const MainRouter = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/workspace/dashboard" />} />
          <Route path="workspace">
            <Route path="dashboard" element={
              <Dashboard />
            } />
            <Route path="customers" element={
              <ContactInteractionState />
            } />
          </Route>

          {/* <Route path="auth">
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="sign-in" element={<SignInPage />} />
            <Route path="reset" element={<PasswordResetPage />} />
            <Route path="request-new-password" element={<RequestNewPasswordPage />} />
            <Route path="confirm"element={<EmailConfirmationPage />} />

          </Route> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>

  )
}